@import "../../styles/partials/variables";
@import "../../styles/libs/mixins/index";

@media print {
    .container-content {
        display: block !important;
        max-width: auto !important;
        min-height: auto !important;
        &::before {
            content: "Available in ProQuest";
        }
    }

    #site-header,
    .container-translate,
    pq-polly,
    .container-actions,
    .container-relatedItems,
    pq-site-footer {
        display: none !important;
    }

    header h1 {
        padding-top: 20px;
    }

    .txt-copyright {
        color: #666 !important;
    }
}


@include sass-themify(){
  @media print {
    header h1 {
      color: sass-themify-get-color('--brandColor') !important;
    }
  }
}
