// Body reset
body {
	font-family: $font-family-base;
	font-size: $font-size-base;
	line-height: $line-height-base;
	color: $text-color;
	background-color: $body-bg;
}

a {
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: underline;
  }

	&:focus {
		@include tab-focus();
	}
}

// Rounded corners
.img-rounded {
	border-radius: $border-radius-large;
}

// Image thumbnails
// Heads up! This is mixin-ed into thumbnails.less for `.thumbnail`.
.img-thumbnail {
	padding: $thumbnail-padding;
	line-height: $line-height-base;
	background-color: $thumbnail-bg;
	border: 1px solid $thumbnail-border;
	border-radius: $thumbnail-border-radius;
	@include transition(all .2s ease-in-out);

	// Keep them at most 100% wide
	@include img-responsive(inline-block);
}

// Horizontal rules
hr {
	margin-top:    $line-height-computed;
	margin-bottom: $line-height-computed;
	border: 0;
	border-top: 1px solid $hr-border;
}

// Prevents content from extending outside the container in mobile / tablet
@media (max-width: $screen-sm) {
	body > .container {
		overflow: hidden;
	}
}

@include sass-themify(){
  a {
    color: sass-themify-get-color('--linkColor');

    &:hover,
    &:focus {
      color: sass-themify-get-color('--linkHoverColor');
    }
  }
}
