// Alerts

@mixin alert-variant($background, $border, $text-color) {
  background-color: $background;
  border-color: $border;
  color: $text-color !important;

  hr {
    border-top-color: darken($border, 5%);
  }
  .alert-link, a {
    color: darken($text-color, 10%) !important;
    font-weight: bold;
  }

}
