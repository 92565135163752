@import "../../styles/partials/variables";
@import "../../styles/libs/mixins/index";

// _scaffolding.scss [START]
a {
  color: $link-default-color;

  &:hover,
  &:focus {
    color: $link-default-hover-color;
  }
}
// _scaffolding.scss [END]

/* you may need to use !important when overwriting styles because styles within
components are more important then those in external stylesheets */

.btn-link {
  color: $link-default-color;

  &:hover,
  &:focus {
    color: $link-default-hover-color;
  }
}

.btn-link.active {
  color: $link-default-hover-color;
  &:hover {
    color: $link-default-hover-color;
  }
}

.btn-link {
  &,
  &:hover,
  &:focus,
  &:active {
    color: $link-default-hover-color;
    &:hover {
      color: $link-default-hover-color;
    }
  }
}

// _navs.scss [START]
.nav .open > a {
  &,
  &:hover,
  &:focus {
    border-color: $link-default-color;
  }
}
// _navs.scss [END]

// _pagination [START]
.pagination > li > a,
.pagination > li > span {
  color: $link-default-color;

  &:hover,
  &:focus {
    color: $link-default-hover-color;
  }
}

// _badges.scss [START]
.badge .list-group-item.active,
.badge .nav-pills > .active > a {
  color: $link-default-color;
}
// _badges.scss [END]

@include sass-themify(){
  /* --------------------------- Bootstrap [START] ------------------------------ */

  // _buttons.scss [START]
  .btn-default {
    @include button-variant($btn-primary-color, sass-themify-get-color('--brandColor'), sass-themify-get-color('--brandColorDarken9'));
  }

  .btn-primary {
    @include button-variant($btn-primary-color, sass-themify-get-color('--brandColor'), sass-themify-get-color('--brandColorDarken9'));
  }
  // _buttons.scss [END]

  .pagination > .active > a,
  .pagination > .active > span {
    &,
    &:hover,
    &:focus {
      background-color: sass-themify-get-color('--brandColor');
      border-color: sass-themify-get-color('--brandColor');
    }
  }
  // _pagination [END]


  /* ---------------- Bootstrap [END] ---------------------------------------------- */

  /* ---------------------------- ProQuest UX framework [START] ------------------------------------------ */
  .nav.nav-stacked .nav-stacked-inverse li.active a {
    color: sass-themify-get-color('--brandColor');

    &:hover, &:focus {
      background-color: sass-themify-get-color('--brandColor');
    }
  }

  .navbar-inverse {
    background: sass-themify-get-color('--brandColor');
  }

  .navbar-inverse .navbar-nav.navbar-below ul > li > a.active {
    border-bottom: 3px solid sass-themify-get-color('--brandColor');
  }

  .form-control {
    &:focus {
      border-color: sass-themify-get-color('--brandColor');
    }
  }

  .checkbox-toggle > input[type=checkbox]:checked + label::before {
    background-color: sass-themify-get-color('--brandColor');
  }

  .radio > input[type=radio]:checked + label::before,
  .checkbox > input[type=checkbox]:checked + label::before {
    background-color: sass-themify-get-color('--brandColor');
  }

  .select-wrapper.form-control > select {
    &:focus {
      box-shadow: inset 0 0 2px 2px sass-themify-get-color('--brandColor');
    }
  }
  // navbar.scss [START]
  @media (max-width: $screen-md) {
    .navbar-collapse {
      background-color: sass-themify-get-color('--brandColorShade45');
    }

    .navbar-collapse .navbar-nav.navbar-below ul > li > a {
      &:hover, &:focus {
        background-color: sass-themify-get-color('--brandColorShade55');
      }
    }

    .navbar-collapse .navbar-nav.navbar-below ul > li > a.active {
      background-color: sass-themify-get-color('--brandColorShade65') !important;
    }
  }

  .navbar-toggle {
    background-color: sass-themify-get-color('--brandColorShade16');
  }

  .diagonal {
    background: sass-themify-get-color('--brandColorShade28'); /* Old browsers */
    background: -webkit-gradient(linear, left bottom, right top, color-stop(0%, sass-themify-get-color('--brandColorShade16')), color-stop(50%, sass-themify-get-color('--brandColorShade16')), color-stop(50%, sass-themify-get-color('--brandColorShade28')), color-stop(100%, sass-themify-get-color('--brandColorShade28'))); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(51deg,  sass-themify-get-color('--brandColorShade28') 0%, sass-themify-get-color('--brandColorShade28') 50%, sass-themify-get-color('--brandColorShade16') 50%, sass-themify-get-color('--brandColorShade16') 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(51deg,  sass-themify-get-color('--brandColorShade28') 0%, sass-themify-get-color('--brandColorShade28') 50%, sass-themify-get-color('--brandColorShade16') 50%, sass-themify-get-color('--brandColorShade16') 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(51deg,  sass-themify-get-color('--brandColorShade28') 0%, sass-themify-get-color('--brandColorShade28') 50%, sass-themify-get-color('--brandColorShade16') 50%, sass-themify-get-color('--brandColorShade16') 100%); /* IE10+ */
    background: linear-gradient(51deg,  sass-themify-get-color('--brandColorShade28') 0%, sass-themify-get-color('--brandColorShade28') 50%, sass-themify-get-color('--brandColorShade16') 50%, sass-themify-get-color('--brandColorShade16') 100%); /* W3C */
  }

  .navbar-inverse .navbar-right > li.help > a:hover,
  .navbar-inverse .navbar-right > li.help > a:focus {
    background-color: sass-themify-get-color('--brandColorShade16');
  }

  .navbar-inverse .navbar-nav > .open > a,
  .navbar-inverse .navbar-nav > .open > a:hover,
  .navbar-inverse .navbar-nav > .open > a:focus {
    background-color: sass-themify-get-color('--brandColorShade16');
  }

  .navbar-inverse .navbar-nav > .active > a {
    background-color: sass-themify-get-color('--brandColorDarken10');

    &:hover {
      background: sass-themify-get-color('--brandColorDarken15');
    }
  }

  .navbar-inverse .navbar-toggle {
    &:focus {
      background-color: sass-themify-get-color('--brandColorDarken15');
    }
  }

  .navbar-inverse .navbar-collapse,
  .navbar-inverse .navbar-form {
    border-color: sass-themify-get-color('--brandColorDarken7');
  }

  @media (min-width: $screen-sm) {
    .navbar-form .form-control {
      border: 1px solid sass-themify-get-color('--brandColorDarken10');
    }
  }

  @media (max-width: 767px) {
    .navbar-inverse .navbar-nav .open .dropdown-menu > li > a {
      &:hover, &:focus {
        background-color: sass-themify-get-color('--brandColorShade55');
      }
    }

    .navbar-inverse .navbar-nav > li > a {
      &:hover,
      &:focus {
        background-color: sass-themify-get-color('--brandColorShade55');
      }
    }
  }

  .navbar-brand.diagonal-thin {
    background: sass-themify-get-color('--brandColorShade28');
  }
  // navbar.scss [END]

  // form.scss [START]
  .form-control {
    &:focus {
      -webkit-box-shadow: inset 1px 2px 0 rgba(0, 0, 0, .075), inset 0 0 0 1px sass-themify-get-color('--brandColor');
      box-shadow: inset 1px 2px 0 rgba(0, 0, 0, .075), inset 0 0 0 1px sass-themify-get-color('--brandColor');
    }
  }

  .radio > input[type=radio]:focus + label::before,
  .radio > input[type=radio]:active + label::before,
  .radio > input[type=radio] + label:active::before,
  .checkbox > input[type=checkbox]:focus + label::before,
  .checkbox > input[type=checkbox]:active + label::before,
  .checkbox > input[type=checkbox] + label:active::before {
    border: 2px solid sass-themify-get-color('--brandColorTint10');
  }

  .selectbox.form-control.open > .options > input[type=radio]:focus + .option {
    background-color: sass-themify-get-color('--brandColor');
  }
  // form.scss [END]

  // _dropdowns.scss [START]
  // Active state
  .dropdown-menu > .active > a {
    &,
    &:hover,
    &:focus {
      background-color: sass-themify-get-color('--brandColor');
    }
  }
  // _dropdowns.scss [END]

  // _buttons.scss [START]
  .btn-default {
    color: $btn-default-color;
    background-color: sass-themify-get-color('--brandColor');
    border-color: sass-themify-get-color('--brandColorDarken9');
    box-shadow: inset 0 -$btn-lip-size 0 sass-themify-get-color('--brandColorDarken5');

    &:hover {
      color: darken($btn-default-color, 5%);
      background-color: sass-themify-get-color('--brandColorDarken7');
      border-color: sass-themify-get-color('--brandColorDarken17');
      box-shadow: inset 0 -$btn-lip-size 0 sass-themify-get-color('--brandColorDarken15');
      outline: none;
      @include transition(.08s);
    }
    &:focus,
    &:active,
    &.active,
    .open > .dropdown-toggle {
      color: darken($btn-default-color, 9%);
      background-color: sass-themify-get-color('--brandColorDarken12');
      border-color: sass-themify-get-color('--brandColorDarken21');
    }
    &:active,
    &.active,
    .open > .dropdown-toggle {
      background-image: none;
    }

    &:active, &:focus {
      box-shadow: inset 2px $btn-lip-size 0 sass-themify-get-color('--brandColorDarken15');
      outline: none;
      @include transition(.08s);
    }
  }

  .btn-default.btn-xs,
  .btn-default.btn-sm {
    box-shadow: inset 0 -($btn-lip-size - 1) 0 sass-themify-get-color('--brandColorDarken5');
    &:hover {
      box-shadow: inset 0 -($btn-lip-size - 1) 0 sass-themify-get-color('--brandColorDarken15');
      outline: none;
      @include transition(.08s);
    }
    &:active, &:focus {
      box-shadow: inset 1px ($btn-lip-size - 1) 0 sass-themify-get-color('--brandColorDarken15');
      outline: none;
      @include transition(.08s);
    }
  }

  .btn-default.disabled,
  .btn-default[disabled],
  .btn-default fieldset[disabled] {
    &,
    &:hover,
    &:focus,
    &:active,
    &.active {
      background-color: sass-themify-get-color('--brandColor');
      border-color: sass-themify-get-color('--brandColorDarken9');
    }
  }

  .btn-default .badge {
    color: sass-themify-get-color('--brandColor');
    background-color: $btn-default-color;
  }

  .navbar.navbar-inverse .btn-default {
    color: $btn-default-color;
    background-color: sass-themify-get-color('--brandColorDarken10');
    border-color: sass-themify-get-color('--brandColorDarken19');
    box-shadow: inset 0 -$btn-lip-size 0 sass-themify-get-color('--brandColorDarken15');

    &:hover {
      color: darken($btn-default-color, 5%);
      background-color: sass-themify-get-color('--brandColorDarken17');
      border-color: sass-themify-get-color('--brandColorDarken26');
      box-shadow: inset 0 -$btn-lip-size 0 sass-themify-get-color('--brandColorDarken25');
    }
    &:focus,
    &:active,
    &.active,
    .open > .dropdown-toggle {
      color: sass-themify-get-color('--brandColorDarken19');
      background-color: sass-themify-get-color('--brandColorDarken22');
      border-color: sass-themify-get-color('--brandColorDarken22');
    }
    &:active, &:focus {
      box-shadow: inset 2px $btn-lip-size 0 sass-themify-get-color('--brandColorDarken25');
    }
    &:active,
    &.active,
    .open > .dropdown-toggle {
      background-image: none;
    }
  }

  .navbar.navbar-inverse .btn-default.disabled,
  .navbar.navbar-inverse .btn-default[disabled],
  .navbar.navbar-inverse .btn-default fieldset[disabled] {
    &,
    &:hover,
    &:focus,
    &:active,
    &.active {
      background-color: sass-themify-get-color('--brandColorDarken10');
      border-color: sass-themify-get-color('--brandColorDarken19');
    }
  }

  .navbar.navbar-inverse .btn-default .badge {
    color: sass-themify-get-color('--brandColorDarken10');
    background-color: $btn-default-color;
  }

  .navbar.navbar-inverse .btn-default.btn-xs,
  .navbar.navbar-inverse .btn-default.btn-sm {
    &:hover {
      box-shadow: inset 0 -($btn-lip-size - 1) 0 sass-themify-get-color('--brandColorDarken25');
    }
    &:active, &:focus {
      box-shadow: inset 1px ($btn-lip-size - 1) 0 sass-themify-get-color('--brandColorDarken25');
    }
  }

  // _buttons.scss [END]

  // _list-group.scss [START]

  // Active class on item itself, not parent
  .list-group-item.active,
  .list-group-item.active:hover,
  .list-group-item.active:focus {
    background-color: sass-themify-get-color('--brandColor');
    border-color: sass-themify-get-color('--brandColor');
  }

  .list-group-item.active .list-group-item-text,
  .list-group-item.active:hover .list-group-item-text,
  .list-group-item.active:focus .list-group-item-text {
    color: sass-themify-get-color('--brandColorLighten40');
  }
  // _list-group.scss [END]

  /* ------------------------------------- ProQuest UX framework [END] ----------------------------------- */

  /* ---------------- Angular Material overwrites [START] -------------------------- */

  .mat-option.mat-selected.mat-primary,
  .mat-primary .mat-option.mat-selected {
    color: sass-themify-get-color('--brandColor');
  }

  .material-radio input:focus + .outer .inner {
    background-color: sass-themify-get-color('--brandColor');
  }
  /* ---------------- Angular Material overwrites [END] -------------------------- */

}

/* ---------------- Angular Material overwrites [START] -------------------------- */

.mat-dialog-title {
  font: 21px/1 $font-family-serif-bold;
  color: #555;
  text-transform: capitalize;
}


.mat-dialog-container {
  border-radius: 4px !important;
  position: relative;

  button.btn-close {
    background: transparent;
    border: none;
    padding: 0;
    position: absolute;
    top: 10px;
    right: 25px;
    width: auto;
    color: #ccc;

    &:hover, &:focus {
      background: transparent;
      color: #555;
    }

    svg {
      width: 32px;
      height: 32px;
    }

    &.mat-focus-indicator,
    &:hover,
    &:focus {
      background: transparent;
    }
  }
}

.mat-select-trigger {
  min-width: 150px !important;
  border: 1px solid #979797;
  border-radius: 4px;
  font-size: 12px !important;
  padding: 10px 0;
}

.mat-select-underline,
.mat-input-underline,
.mat-form-field-underline {
  display: none;
}

.mat-select:focus-visible:not(.mat-select-disabled) .mat-select-trigger,
.mat-select:focus-visible:not(.mat-select-disabled).mat-primary .mat-select-trigger,
.mat-select:focus:not(.mat-select-disabled) .mat-select-trigger,
.mat-select:focus:not(.mat-select-disabled).mat-primary .mat-select-trigger {
  border: 2px solid #0360cc
}

.mat-option:hover:not(.mat-option-disabled),
.mat-option:focus:not(.mat-option-disabled),
.mat-option:focus-visible:not(.mat-option-disabled) {
  outline: 2px solid #0360cc;
}

.mat-select-value {
  padding-left: 4px;
  color: #777 !important;
}

.mat-form-field-infix {
  padding: 0 !important;
  border-top: none !important;
}

.mat-form-field-wrapper {
  padding-bottom: 0 !important;
  margin: 0 !important;
}

.mat-form-field-label-wrapper {
  z-index: 100;
}

.mat-form-field-label-wrapper {
  top: -0.5em !important;
  left: 6px !important;
}

.mat-form-field-should-float .mat-form-field-label-wrapper {
  visibility: hidden;
}

.mat-autocomplete-panel.mat-autocomplete-panel-below  {
  top: 0 !important;
}

.mat-option  {
  line-height: 29px !important;
  height: 30px !important;
}

.mat-option.mat-active {
  outline: 2px solid #0360cc;
}

input.mat-input-element {
  margin-top: -0.1em !important;
}

/* ---------------------------- Angular Material overwrites [END] -------------------------------------- */


