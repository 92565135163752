//
// Alerts
// --------------------------------------------------

// Base styles
// -------------------------
.alert {

	// Headings for larger alerts
	h4 {
		// Specified for the h4 to prevent conflicts of changing @headings-color
		color: inherit;
		text-transform: none;
		letter-spacing: 0;
		font-weight: 300;
	}
}

.alert-success {
	@include alert-variant($alert-success-bg, $alert-success-border, $alert-success-text);
}

.alert-info {
	@include alert-variant($alert-info-bg, $alert-info-border, $alert-info-text);
}

.alert-warning {
	@include alert-variant($alert-warning-bg, $alert-warning-border, $alert-warning-text);
}

.alert-danger {
	@include alert-variant($alert-danger-bg, $alert-danger-border, $alert-danger-text);
}

@include sass-themify(){
  .alert {
    h4 {
      color: sass-themify-get-color('--brandColor');
    }
  }
}
