//
// Labels
// --------------------------------------------------

.label {
	font-weight: 300;
}

.label-warning {
	@include label-variant(lighten($label-warning-bg, 10%));
}

.label-danger {
	@include label-variant(lighten($label-danger-bg, 10%));
}