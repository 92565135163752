//
// Buttons
// --------------------------------------------------

// Base styles
// --------------------------------------------------

.btn {

	&:hover,
	&:focus {
		color: darken($btn-default-color, 10%);
	}

	&:active,
	&.active {
		@include box-shadow(inset 1px ($btn-lip-size - 1) 0 rgba(0, 0, 0, .125));
		&:hover {
			@include box-shadow(inset 1px ($btn-lip-size - 1) 0 rgba(0, 0, 0, .125));
		}
	}

	&.disabled,
	&[disabled],
	fieldset[disabled] & {
		cursor: not-allowed;
	}
}

.btn-secondary {
	@include button-variant($btn-secondary-color, $btn-secondary-bg, darken($btn-secondary-border, 4%));
	@include box-shadow(inset 0 -$btn-lip-size 0 $btn-secondary-lip);
	&:hover {
		@include box-shadow(inset 0 -$btn-lip-size 0 darken($btn-secondary-lip, 10%));
		outline: none;
		@include transition(.08s);
	}
	&:active, &:focus {
		@include box-shadow(inset 2px $btn-lip-size 0 darken($btn-secondary-lip, 10%));
		outline: none;
		@include transition(.08s);
	}
	&.btn-xs, &.btn-sm {
		@include box-shadow(inset 0 -($btn-lip-size - 1) 0 $btn-secondary-lip);
		&:hover {
			@include box-shadow(inset 0 -($btn-lip-size - 1) 0 darken($btn-secondary-lip, 10%));
			outline: none;
			@include transition(.08s);
		}
		&:active, &:focus {
			@include box-shadow(inset 1px ($btn-lip-size - 1) 0 darken($btn-secondary-lip, 10%));
			outline: none;
			@include transition(.08s);
		}
	}
}

.btn-primary {
	@include button-variant($btn-primary-color, $btn-primary-bg, $btn-primary-border);
	@include box-shadow(inset 0 -$btn-lip-size 0 $btn-primary-lip);
	@include transition(.08s);
	&:hover {
		@include box-shadow(inset 0 -$btn-lip-size 0 darken($btn-primary-lip, 10%));
		outline: none;
		@include transition(.08s);
	}
	&:active, &:focus {
		@include box-shadow(inset 2px $btn-lip-size 0 darken($btn-primary-lip, 10%));
		outline: none;
		@include transition(.08s);
	}
	&.btn-xs, &.btn-sm {
		@include box-shadow(inset 0 -($btn-lip-size - 1) 0 $btn-primary-lip);
		&:hover {
			@include box-shadow(inset 0 -($btn-lip-size - 1) 0 darken($btn-primary-lip, 10%));
			outline: none;
			@include transition(.08s);
		}
		&:active, &:focus {
			@include box-shadow(inset 1px ($btn-lip-size - 1) 0 darken($btn-primary-lip, 10%));
			outline: none;
			@include transition(.08s);
		}
	}
}

// Success appears as green
.btn-success {
	@include button-variant($btn-success-color, $btn-success-bg, $btn-success-border);
	@include box-shadow(inset 0 -$btn-lip-size 0 $btn-success-lip);
	&:hover {
		@include box-shadow(inset 0 -$btn-lip-size 0 darken($btn-success-lip, 10%));
		outline: none;
		@include transition(.08s);
	}
	&:active, &:focus {
		@include box-shadow(inset 2px $btn-lip-size 0 darken($btn-success-lip, 10%));
		outline: none;
		@include transition(.08s);
	}
	&.btn-xs, &.btn-sm {
		@include box-shadow(inset 0 -($btn-lip-size - 1) 0 $btn-success-lip);
		&:hover {
			@include box-shadow(inset 0 -($btn-lip-size - 1) 0 darken($btn-success-lip, 10%));
			outline: none;
			@include transition(.08s);
		}
		&:active, &:focus {
			@include box-shadow(inset 1px ($btn-lip-size - 1) 0 darken($btn-success-lip, 10%));
			outline: none;
			@include transition(.08s);
		}
	}
}

// Info appears as blue-green
.btn-info {
	@include button-variant($btn-info-color, $btn-info-bg, $btn-info-border);
	@include box-shadow(inset 0 -$btn-lip-size 0 $btn-info-lip);
	&:hover {
		@include box-shadow(inset 0 -$btn-lip-size 0 darken($btn-info-lip, 10%));
		outline: none;
		@include transition(.08s);
	}
	&:active, &:focus {
		@include box-shadow(inset 2px $btn-lip-size 0 darken($btn-info-lip, 10%));
		outline: none;
		@include transition(.08s);
	}
	&.btn-xs, &.btn-sm {
		@include box-shadow(inset 0 -($btn-lip-size - 1) 0 $btn-info-lip);
		&:hover {
			@include box-shadow(inset 0 -($btn-lip-size - 1) 0 darken($btn-info-lip, 10%));
			outline: none;
			@include transition(.08s);
		}
		&:active, &:focus {
			@include box-shadow(inset 1px ($btn-lip-size - 1) 0 darken($btn-info-lip, 10%));
			outline: none;
			@include transition(.08s);
		}
	}
}

// Warning appears as orange
.btn-warning {
	@include button-variant($btn-warning-color, $btn-warning-bg, $btn-warning-border);
	@include box-shadow(inset 0 -$btn-lip-size 0 $btn-warning-lip);
	&:hover {
		@include box-shadow(inset 0 -$btn-lip-size 0 darken($btn-warning-lip, 10%));
		outline: none;
		@include transition(.08s);
	}
	&:active, &:focus {
		@include box-shadow(inset 2px $btn-lip-size 0 darken($btn-warning-lip, 10%));
		outline: none;
		@include transition(.08s);
	}
	&.btn-xs, &.btn-sm {
		@include box-shadow(inset 0 -($btn-lip-size - 1) 0 $btn-warning-lip);
		&:hover {
			@include box-shadow(inset 0 -($btn-lip-size - 1) 0 darken($btn-warning-lip, 10%));
			outline: none;
			@include transition (.08s);
		}
		&:active, &:focus {
			@include box-shadow(inset 1px ($btn-lip-size - 1) 0 darken($btn-warning-lip, 10%));
			outline: none;
			@include transition(.08s);
		}
	}
}

// Danger and error appear as red
.btn-danger {
	@include button-variant($btn-danger-color, $btn-danger-bg, $btn-danger-border);
	@include box-shadow(inset 0 -$btn-lip-size 0 $btn-danger-lip);
	&:hover {
		@include box-shadow(inset 0 -$btn-lip-size 0 darken($btn-danger-lip, 10%));
		outline: none;
		@include transition(.08s);
	}
	&:active, &:focus {
		@include box-shadow(inset 2px $btn-lip-size 0 darken($btn-danger-lip, 10%));
		outline: none;
		@include transition(.08s);
	}
	&.btn-xs, &.btn-sm {
		@include box-shadow(inset 0 -($btn-lip-size - 1) 0 $btn-danger-lip);
		&:hover {
			@include box-shadow(inset 0 -($btn-lip-size - 1) 0 darken($btn-danger-lip, 10%));
			outline: none;
			@include transition(.08s);
		}
		&:active, &:focus {
			@include box-shadow(inset 1px ($btn-lip-size - 1) 0 darken($btn-danger-lip, 10%));
			outline: none;
			@include transition(.08s);
		}
	}
}

// Outline button
.btn-outline {
	@include button-variant($btn-outline-color, $btn-outline-bg, $btn-outline-border);
	border-width: 2px;
	&:hover {
		@include button-variant(darken($btn-outline-color, 10%), $btn-outline-bg, darken($btn-outline-border, 10%));
		outline: none;
		@include transition(.08s);
	}
	&:active, &:focus {
		@include button-variant(darken($btn-outline-color, 20%), $btn-outline-bg, darken($btn-outline-border, 20%));
		outline: none;
		@include transition(.08s);
	}
	&.active {
		@include button-variant(darken($btn-outline-color, 20%), $btn-outline-bg, darken($btn-outline-border, 20%));
		&:hover {
			@include button-variant(darken($btn-outline-color, 20%), $btn-outline-bg, darken($btn-outline-border, 20%));
		}
	}
}

// Link buttons
// -------------------------

// Make a button look and behave like a link
.btn-link {
	cursor: pointer;

	&.active {
		box-shadow: none;
		&:hover {
			box-shadow: none;
			text-decoration: none;
		}
	}
	&,
	&:hover,
	&:focus,
	&:active {
		box-shadow: none;
		&:hover {
			box-shadow: none;
		}
	}
	&:hover,
	&:focus {
		text-decoration: underline;
		box-shadow: none;
	}
	&[disabled],
	fieldset[disabled] & {
		&:hover,
		&:focus {
			box-shadow: none;
		}
	}
}
