//
// Popovers
// --------------------------------------------------

.popover-title {
	font-weight: normal;
	line-height: 18px;
}

.popover {
	&.top > .arrow {
		border-top-color: $popover-arrow-outer-fallback-color; // IE8 fallback
		border-top-color: $popover-arrow-outer-color;
	}
	&.right > .arrow {
		border-right-color: $popover-arrow-outer-fallback-color; // IE8 fallback
		border-right-color: $popover-arrow-outer-color;
	}
	&.bottom > .arrow {
		border-bottom-color: $popover-arrow-outer-fallback-color; // IE8 fallback
		border-bottom-color: $popover-arrow-outer-color;
	}

	&.left > .arrow {
		border-left-color: $popover-arrow-outer-fallback-color; // IE8 fallback
		border-left-color: $popover-arrow-outer-color;
	}
}

// Walkthrough tour styles

.tour-backdrop {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1100;
	background-color: #000;
	opacity: 0.8;
}
.tour-step-backdrop {
	position: relative;
	z-index: 1101;
	background: inherit;
}
.tour-step-background {
	position: absolute;
	z-index: 1100;
	background: inherit;
	border-radius: 6px;
}
.popover[class*="tour-"] {
	z-index: 1100;
	min-width: ($popover-max-width - 50);
}
.popover[class*="tour-"] .popover-navigation {
	padding: 9px 14px;
}
.popover[class*="tour-"] .popover-navigation *[data-role="end"] {
	float: right;
}
.popover[class*="tour-"] .popover-navigation *[data-role="prev"],
.popover[class*="tour-"] .popover-navigation *[data-role="next"],
.popover[class*="tour-"] .popover-navigation *[data-role="end"] {
	cursor: pointer;
}
.popover[class*="tour-"] .popover-navigation *[data-role="prev"].disabled,
.popover[class*="tour-"] .popover-navigation *[data-role="next"].disabled,
.popover[class*="tour-"] .popover-navigation *[data-role="end"].disabled {
	cursor: default;
}
.popover[class*="tour-"].orphan {
	position: fixed;
	margin-top: 0;
}
.popover[class*="tour-"].orphan .arrow {
	display: none;
}