@import "../../styles/partials/variables";

body {
	background: #fff;
}

main {
	min-height: 100%;
	min-height: 100vh;
}

[class^="gr-"], [class*=" gr-"] {
	height: 32px;
	width: 32px;
	display: inline-block;
	fill: currentColor;
}

a {
  outline: none;
  &:focus, &:active {
		color: $link-focus-color;
    outline: none;
    text-decoration: underline;
	}
}

button, a.btn {
  outline: none;
  &:focus, &:active {
    text-decoration: none;
  }
}

/*
disabled till the story re-enable this design change has been fully flushed out
a[href],
area[href],
input:not([disabled]),
select:not([disabled]),
textarea:not([disabled]),
button:not([disabled]),
iframe,
[tabindex],
[contentEditable=true] {
  &:not([tabindex='-1']) {
    &:focus-visible {
      outline: 3px solid #B200ED;
    }
  }
}*/

h1 {
	margin: 0;
	padding: 0;
	font: 26px/1 $font-family-serif-bold;
	color: $page-title-color;
}

h3 {
  font-size: 1.5rem;
  padding-bottom: 10px;
  text-transform: uppercase;
  color: #555;
  font-family: $font-family-sans-serif-bold;
}

.container-content {
	box-shadow: 0 0 1px 0px rgba(0, 0, 0, 0.2);
	max-width: 1390px;
	min-height: 100%;
	min-height: 100vh;
	margin: 0 auto;
	background: #fff;
	section > h2 {
		font: 26px/1 'aleobold', serif;
		padding: 0 0 16px;
		border-bottom: 1px solid $section-title-btm-border-color;
		margin: 0 40px 20px 0;
		color: $section-title-color;
	}
}

.navbar-brand img {
	width: 24px;
	height: 24px;
}

.overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  background-color: $overlay-color;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  opacity: 0.8;
}

.lightbox {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 10000;
  text-align: center;
  line-height: 0;
  font-weight: normal;
  box-sizing: content-box;
}

@media (min-width: $screen-xs) {
	.container-topics {
		ul > li {
			width: 33%;
		}
	}
}

@media (min-width: $screen-lg) {
  .hidden-lg {
    display: none !important;
  }
}

.well {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
}

.well-lg {
  padding: 24px;
  border-radius: 6px;
}

.well-sm {
  padding: 9px;
  border-radius: 3px;
}
