
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$ui-primary: mat.define-palette(mat.$indigo-palette);
$ui-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$ui-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$ui-theme: mat.define-light-theme((
  color: (
    primary: $ui-primary,
    accent: $ui-accent,
    warn: $ui-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($ui-theme);

@import "../styles/partials/theme";
@import "../styles/libs/mixins/index";
@import "../styles/partials/variables";
@import "../../node_modules/bourbon/core/_bourbon";
@import "../styles/libs/bootstrap/bootstrap";
@import "../styles/libs/proquest-uikit/_proquest-uikit";
@import "../../node_modules/@angular/material/prebuilt-themes/indigo-pink.css";
@import "../styles/libs/videogular2/videogular";

@import "partials/frameworks-override";

/* app style imports */
@import "partials/global";
@import "partials/print";

// styles applied on :host are applied on the current component, "app" in this case
:host {
  display: block;
}

html, body {
  height: 100%;
  margin: 0;
}

body {
  display: flex;
  flex-direction: column;
}

pq-notification,
#consent_blackbar,
pq-site-footer {
  /* We want the header to have a static height,
   it will always take up just as much space as it needs.  */
  /* 0 flex-grow, 0 flex-shrink, auto flex-basis */
  flex: 0 0 auto;
}


.page-container {
  min-height: calc(100vh - 170px);

  /* By setting flex-grow to 1, the main content will take up
   all of the remaining space on the page.
   The other elements have flex-grow: 0 and won't contest the free space. */
  /* 1 flex-grow, 0 flex-shrink, auto flex-basis */
  flex: 1 0 auto;
}

b, strong {
  font-family: $font-family-sans-serif-bold;
}

.oh {
  overflow: hidden;
}

.skip {
  position: absolute;
  top: -1000px;
  left: -1000px;
  height: 1px;
  width: 1px;
  z-index: 9999;
  background: #fff;
  text-align: left;
  overflow: hidden;

  &:active,
  &:focus,
  &:hover {
    left: 0;
    top: 0;
    width: auto;
    height: auto;
    overflow: visible;
    padding: 3px;
  }
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
