/** Footer **/
.footer-site {
	background-color: #494949;
	overflow: hidden;
	color: darken(white, 10%);
	padding: 18px;
	a, a:hover, a:focus {
		color: darken(white, 10%);
	}
	p, ul {
		margin: 0;
	}
	p {
		text-align: right;
	}
	.footer-logo {
		background: url("#{$image-path}/pq-logo-clarivate.svg") no-repeat bottom;
		height: 70px;
		width: 180px;
		margin-top: 0;
	}
}

.top-footer {
	background-color: #555;
	color: #fff;
	margin-top: 20px;
	padding: 18px;
	display: block;
	clear: both;
	a {
		color: #E6E6E6;
	}
	h5 {
		color: #E6E6E6;
		font-size: 16px;
		margin-bottom: 5px;
	}
	ul.footer-links {
		list-style: outside none none;
		padding-left: 0;
	}
	ul.footer-links li {
		margin-bottom: 7px;
	}
}



/*--Mobile----------*/
@media (max-width: $screen-sm) {

	.top-footer ul.footer-links {
		margin-bottom: 25px;
	}

	.footer-site {
		height: auto;
		text-align: center;
		p {
			text-align: center;
		}
		p.pull-right, ul.pull-right,
		p.pull-left, ul.pull-left {
			width: 100%;
			float: none !important;
		}
		.footer-logo {
			margin: 0 auto 20px auto;
		}
	}
}
