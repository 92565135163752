//
// Navbars
// --------------------------------------------------


// Wrapper and base class
//
// Provide a static navbar from which we expand to create full-width, fixed, and
// other navbar variations.

.navbar {
	min-height: $navbar-height;
	margin-bottom: $navbar-margin-bottom;
	border: none;
	position: relative;
	border-radius: 0;
}

.navbar-inverse .navbar-nav > li > .product-name span.subtitle {
	font-family:$font-family-sans-serif;
	font-size:12px;
}

.navbar-collapse {
	padding:0;
}

.navbar {
	.navbar-toggle:focus {
		outline: none;
	}
	.navbar-nav.navbar-below {
		width:100%;
		ul > li > a {
			padding-top: 7px;
			padding-bottom: 8px;
			border-bottom:3px solid transparent;
		}

	}
	@media (max-width: $screen-md) {

		.navbar-collapse {
			border: none;
			box-shadow: none;
			.navbar-nav + .navbar-nav {
				border-top: 1px solid rgba(0,0,0,0.5);
			}
			.navbar-nav > li.navbar-right {
				border-top: 1px solid rgba(0,0,0,0.5);
			}
			.navbar-nav > li > a {
				padding-top: 7px;
				padding-bottom: 7px;
				padding-left:15px;
			}
			.navbar-nav.navbar-below {
				position: static;
				height: auto;
				background: none;
				border-bottom: none;
				color: #fff;
				.uxf-icon {
					color: white;
				}
				//active state for mobile
				ul > li > a {
					color: white;
					background-color: transparent;
					border:none !important;
					&:hover, &:focus {
						color: darken(white, 10%);
						border:none;
					}
				}
				ul > li > a.active  {
					border-bottom-color: transparent;
					@include box-shadow(none);
					&:hover, &:focus {
						color: white;
					}
				}
				> .dropdown {
					> a, > a:hover, > a:focus {
						.caret {
							border-top-color: white;
							border-bottom-color: white;
						}
					}
				}
			}
		}
	}
}

.dropdown-menu > li > a {
	padding:5px 10px;
}

.navbar-toggle {
	border-radius: 5px;
	padding: 7px 9px;
	margin-top:12px;
}

.navbar-toggle .icon-bar {
	height:3px;
}

// ProQuest Logo (old browser fallback)
div.pq-logo {
	background-image: url('#{$image-path}/pq-logo.png');
}

.navbar-nav li div.product-name {
	padding: 24px 14px 12px 12px;
	position: relative;
	a {
		color: white;
    white-space: nowrap;
		&:hover, &:active {
			text-decoration: none;
		}

    &:focus {
      text-decoration-skip: auto;
    }
	}
	div.pq-logo {
		background-image: url('#{$image-path}/pq-logo-header.svg'), none;
		background-size: 53px 11px;
		height: 11px;
		width: 53px;
		position: absolute;
		top: 15px;
		left: 12px;
		display: inline-block;
	}
}

.diagonal {
	&:focus {
    transform: scale(-1,1);
  }
}


.navbar-nav > li > a {
	padding: 19px 10px;
	line-height: 21px;
}

.navbar-nav > li > a.diagonal,
.navbar-nav > li > div.diagonal {
	padding: 15px 14px;
}



.navbar-nav {
	margin:0;
}

.navbar-inverse .navbar-right > li > a:hover, .navbar-inverse .navbar-right > li > a:focus,
.navbar-inverse .navbar-right > li.help > a:hover, .navbar-inverse .navbar-right > li.help > a:focus,
.navbar-inverse .navbar-nav > li > a:hover, .navbar-inverse .navbar-nav > li > a:focus {
	color:#fff;
}

.navbar-inverse .navbar-nav > .dropdown > a .caret .caret:hover, .navbar-inverse .navbar-nav > .dropdown > a .caret:focus {
	border-bottom-color: #fff;
	border-top-color: #fff;
}

.navbar-inverse .navbar-nav > .open > a, .navbar-inverse .navbar-nav > .open > a:hover, .navbar-inverse .navbar-nav > .open > a:focus {
	color:#fff;
}

.navbar-inverse .navbar-below .navbar-nav > .open > a, .navbar-inverse .navbar-below .navbar-nav > .open > a:focus, .navbar-inverse .navbar-below .navbar-nav > .open > a:hover {
	background-color: #e0e0df;
	color: $gray;
}

.navbar-inverse {
	.navbar-brand {
		font-family: $font-family-serif;
		font-weight: 300;
		font-size: 22px;
		line-height: 27px;
		height:60px;
		color: white;
	}
	.navbar-brand .uxf-icon {
		font-size:22px;
		color:#fff;
	}
	// Styles pertaining to the gray utility nav
	.navbar-nav.navbar-below {
		background: $navbar-default-bg;
		color: $gray;
		.uxf-icon {
			color: $navbar-default-link-color;
		}
		ul  > li > a {
			color: $gray;
			&:hover, &:focus {
				background-color: #e0e0df;
				outline: none;
				border-bottom: 3px solid transparent;
			}
		}
		ul > li > a.active {
			background-color: transparent;
			padding-bottom: 6px;
			&:hover, &:focus {
				color: $gray;
				background-color: #e0e0df;
			}
		}
		> .dropdown {
			> a, > a:hover, > a:focus {
				.caret {
					border-top-color: $gray;
					border-bottom-color: $gray;
					opacity: 0.6;
				}
			}
		}
		> .open {
			> a, > a:hover, > a:focus {
				color: $gray;
				background-color: #e0e0df;
				.caret {
					border-top-color: $gray;
					border-bottom-color: $gray;
				}
			}
		}
	}
	.uxf-icon {
		font-size:16px;
	}
	li.help {
		margin-right: 10px;
	}
}

// Inverse navbar

.navbar-inverse {

	.navbar-brand {
		color: white;
		&:hover,
		&:focus {
			color: darken(white, 10%);
			background-color: transparent;
		}
	}

	.navbar-text {
		color: white;
	}

	.navbar-nav {
		> li > a {
			color: white;

			&:hover,
			&:focus {
				color: white;
			}
		}
		> .active > a {

			&,
			&:hover,
			&:focus {
				color: white;
			}

		}
		> .disabled > a {
			&,
			&:hover,
			&:focus {
				color: white;
				background-color: transparent;
			}
		}
	}

	// Darken the responsive nav toggle
	.navbar-toggle {
		border-color: transparent;
		&:hover,

		.icon-bar {
			background-color: white;
		}
	}

	.navbar-link {
		color: white;
		&:hover {
			color: darken(white, 10%);
		}
	}
}
@media (min-width: $screen-sm) {
	.navbar {
		border-radius: 0;
	}
	.navbar-below {
		ul.nav.navbar-nav {
			margin-left: 10px;
			margin-right: 10px;
		}
	}

	ul.nav.navbar-nav.navbar-right {
		margin-right: 10px;
		margin-left: 10px;
	}
	.navbar-form {
		margin-top: 12px;
		.form-control {
			margin-right: 3px;
			margin-left: 3px;
		}
	}
}

@media (max-width: 767px) {
	.navbar-inverse {
		.navbar-nav {
			.open {
				.dropdown-menu > li > a {
					color: white;
				}
			}
			> li > a.navbar-brand {
				&:hover,
				&:focus {
					background-color: transparent;
				}
			}
		}
	}
}

.navbar-collapse {
	padding-bottom: 5px;
}

@media (max-width: 375px) {
	.navbar-inverse {
		background-position: 0px -6px;
	}
	a.navbar-brand.diagonal {
		display: none;
	}
}

//skinny diagonal for products that don't want an icon - move the class to the li above - DF
.navbar-brand.diagonal-thin {
	width: 5px;
	padding: 0;
}
