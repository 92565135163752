// global color styles
@import "theme";

// Theme color
$brand-primary: #118289;
$brand-success:         #5cb85c !default;
$brand-info:            #5bc0de !default;
$brand-warning:         #f0ad4e !default;
$brand-danger:          #d9534f !default;

/* colors */
$gray-base:              #000 !default;
$gray-darker:            lighten($gray-base, 13.5%) !default; // #222
$gray-dark:              lighten($gray-base, 20%) !default;   // #333
$gray:                   lighten($gray-base, 33.5%) !default; // #555
$gray-light:             lighten($gray-base, 46.7%) !default; // #777
$gray-lighter:           lighten($gray-base, 93.5%) !default; // #eee

//== Scaffolding
//
//## Settings for some of the most global styles.

//** Background color for `<body>`.
$body-bg:               #fff !default;
//** Global text color on `<body>`.
$text-color:            $gray-dark !default;

//** Link hover decoration.
$link-hover-decoration: underline !default;

$link-focus-color: #094145;

$page-title-color: #6d6d6d;

$section-title-color: #6d6d6d;
$section-title-btm-border-color: #ccc;
$section-box-shadow-color: rgba(0,0,0,0.1);

$overlay-color: $gray-base;

$input-border: #979797;
$input-bkgrd: #fff;

$alert-danger-text:              #a94442 !default;

$error-text: #f00;

//== Typography
//
//## Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif:  'Roboto', Helvetica, Arial, sans-serif;
$font-family-sans-serif-bold:  Roboto-Bold, Helvetica, Arial, sans-serif;
$font-family-serif: 'aleoregular', serif;
$font-family-serif-italic: 'aleoitalic', serif;
$font-family-serif-bold: 'aleobold', serif;
$font-family-serif-light: 'aleolight', serif;
$font-family-sans-serif-light: 'Roboto Light', Helvetica, Arial, sans-serif;
$font-family-serif-italic-light: 'aleolightitalic', serif;
$font-family-serif-bold-italic: 'aleobolditalic', serif;
$font-family-condensed:   'Roboto Condensed', Helvetica, Arial, sans-serif;
//** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
$font-family-monospace:   Menlo, Monaco, Consolas, 'Courier New', monospace;
$font-family-base:        $font-family-sans-serif;

$font-size-base:          14px;
$font-size-large:         ceil(($font-size-base * 1.25)); // ~18px
$font-size-small:         ceil(($font-size-base * 0.85)); // ~12px

$font-size-h1:            floor(($font-size-base * 2.3)); // ~32px
$font-size-h2:            floor(($font-size-base * 2)); // ~28px
$font-size-h3:            ceil(($font-size-base * 1.7)); // ~24px
$font-size-h4:            ceil(($font-size-base * 1.25)); // ~18px
$font-size-h5:            ceil(($font-size-base * 1.14)); // ~16px
$font-size-h6:            $font-size-base; // ~14px


//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
//** Deprecated `$screen-xs` as of v3.0.1
$screen-xs:                  480px;
//** Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min:              $screen-xs;
//** Deprecated `$screen-phone` as of v3.0.1
$screen-phone:               $screen-xs-min;

// Small screen / tablet
//** Deprecated `$screen-sm` as of v3.0.1
$screen-sm:                  768px;
$screen-sm-min:              $screen-sm;
//** Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet:              $screen-sm-min;

// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md:                  992px;
$screen-md-min:              $screen-md;
//** Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop:             $screen-md-min;

// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-lg:                  1200px;
$screen-lg-min:              $screen-lg;
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop:          $screen-lg-min;

// Extra large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-xl:                  1500px;
$screen-xl-min:              $screen-xl;
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop:          $screen-xl-min;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:              ($screen-sm-min - 1);
$screen-sm-max:              ($screen-md-min - 1);
$screen-md-max:              ($screen-lg-min - 1);
$screen-lg-max:              ($screen-xl-min - 1);

// ux file paths
$font-path: "/assets/fonts/proquest-uikit";
$image-path: "/assets/img/proquest-uikit";

//** Unit-less `line-height` for use in components like buttons.
$line-height-base:        1.5;
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
$line-height-computed:    floor(($font-size-base * $line-height-base));

$border-radius-base:        4px;
$border-radius-large:       6px;
$border-radius-small:       3px;

//** Global color for active items (e.g., navs or dropdowns).
$component-active-color:    #fff;

//** Disabled cursor for form controls and buttons.
$cursor-disabled:                not-allowed !default;

//== Components
//
//## Define common padding and border radius sizes and more.

$padding-base-vertical:     6px;
$padding-base-horizontal:   12px;

$padding-large-vertical:    10px;
$padding-large-horizontal:  16px;

$padding-small-vertical:    5px;
$padding-small-horizontal:  10px;

$padding-xs-vertical:       1px;
$padding-xs-horizontal:     5px;

$line-height-large:         1.3333333; // extra decimals for Win 8.1 Chrome
$line-height-small:         1.5;

$border-radius-base:        4px;
$border-radius-large:       6px;
$border-radius-small:       3px;

//** Width of the `border` for generating carets that indicate dropdowns.
$caret-width-base:          4px !default;
//** Carets increase slightly in size for larger components.
$caret-width-large:         5px !default;

//== Links
$link-default-color: #0C58BF;
$link-default-hover-color: darken($link-default-color, 5%);

//== Buttons
//
//## For each of the UX Frameworks's buttons, define text, background and border color.
$btn-font-weight:                normal;
$btn-lip-size:                   3px;

$btn-default-color:              #fff;

$btn-secondary-color:            $gray;
$btn-secondary-bg:               $gray-lighter;
$btn-secondary-border:           darken($gray-lighter, 11%);
$btn-secondary-lip:              darken($gray-lighter, 6%);

$btn-outline-color:              white;
$btn-outline-bg:                 transparent;
$btn-outline-border:             white;

$btn-primary-color:              #fff;
$btn-primary-bg:                 $brand-primary;
$btn-primary-border:             darken($brand-primary, 9%);
$btn-primary-lip:                darken($brand-primary, 5%);

$btn-success-color:              #fff;
$btn-success-bg:                 $brand-success;
$btn-success-border:             darken($btn-success-bg, 7%);
$btn-success-lip:                darken($btn-success-bg, 4%);

$btn-info-color:                 #fff;
$btn-info-bg:                    $brand-info;
$btn-info-border:                darken($btn-info-bg, 9%);
$btn-info-lip:                   darken($btn-info-bg, 5%);

$btn-warning-color:              #fff;
$btn-warning-bg:                 lighten($brand-warning, 10%);
$btn-warning-border:             darken($btn-warning-bg, 9%);
$btn-warning-lip:                darken($btn-warning-bg, 5%);

$btn-danger-color:               #fff;
$btn-danger-bg:                  lighten($brand-danger, 10%);
$btn-danger-border:              darken($btn-danger-bg, 9%);
$btn-danger-lip:                 darken($btn-danger-bg, 5%);

$btn-link-disabled-color:        $gray-light;

// Allows for customizing button radius independently from global border radius
$btn-border-radius-base:         $border-radius-base !default;
$btn-border-radius-large:        $border-radius-large !default;
$btn-border-radius-small:        $border-radius-small !default;

//== Form states and alerts
//
//## Define colors for form feedback states and, by default, alerts.

$state-success-text:             #3c763d !default;
$state-success-bg:               #dff0d8 !default;
$state-success-border:           darken(adjust-hue($state-success-bg, -10), 5%) !default;

$state-info-text:                #31708f !default;
$state-info-bg:                  #d9edf7 !default;
$state-info-border:              darken(adjust-hue($state-info-bg, -10), 7%) !default;

$state-warning-text:             #8a6d3b !default;
$state-warning-bg:               #fcf8e3 !default;
$state-warning-border:           darken(adjust-hue($state-warning-bg, -10), 5%) !default;

$state-danger-text:              #a94442 !default;
$state-danger-bg:                #f2dede !default;
$state-danger-border:            darken(adjust-hue($state-danger-bg, -10), 5%) !default;


//== Forms
//
//##

//** `<input>` background color
$input-bg:                       #fff;
//** `<input disabled>` background color
$input-bg-disabled:              $gray-lighter;

//** Text color for `<input>`s
$input-color:                    $gray;
//** `<input>` border color
$input-border:                   #ccc;
//** `<input>` border radius
$input-border-radius:            $border-radius-base;
//** Border color for inputs on focus
$input-border-focus:             $brand-primary;

//** Placeholder text color
$input-color-placeholder:        $gray-light;

//** Default `.form-control` height
$input-height-base:              ($line-height-computed + ($padding-base-vertical * 2) + 2);
//** Large `.form-control` height
$input-height-large:             (ceil($font-size-large * $line-height-large) + ($padding-large-vertical * 2) + 2);
//** Small `.form-control` height
$input-height-small:             (floor($font-size-small * $line-height-small) + ($padding-small-vertical * 2) + 2);

$legend-color:                   $gray-dark;
$legend-border-color:            #e5e5e5;

//** Background color for textual input addons
$input-group-addon-bg:           $gray-lighter;
//** Border color for textual input addons
$input-group-addon-border-color: $input-border;

// TODO: Rename `$input-border-radius` to `$input-border-radius-base` in v4
//** Default `.form-control` border radius
// This has no effect on `<select>`s in some browsers, due to the limited stylability of `<select>`s in CSS.
$input-border-radius:            $border-radius-base !default;

//** Large `.form-control` border radius
$input-border-radius-large:      $border-radius-large !default;

//** Small `.form-control` border radius
$input-border-radius-small:      $border-radius-small !default;

//** `.form-group` margin
$form-group-margin-bottom:       15px !default;

//== Labels
//
//##

//** Default label background color
$label-default-bg:            $gray-light;
//** Primary label background color
$label-primary-bg:            $brand-primary;
//** Success label background color
$label-success-bg:            $brand-success;
//** Info label background color
$label-info-bg:               $brand-info;
//** Warning label background color
$label-warning-bg:            $brand-warning;
//** Danger label background color
$label-danger-bg:             $brand-danger;

//** Default label text color
$label-color:                 #fff;
//** Default text color of a linked label
$label-link-hover-color:      #fff;

//== Dropdowns
//
//## Dropdown menu container and contents.

//** Background for the dropdown menu.
$dropdown-bg:                    #fff;
//** Dropdown menu `border-color`.
$dropdown-border:                rgba(0,0,0,.15);
//** Dropdown menu `border-color` **for IE8**.
$dropdown-fallback-border:       #ccc;
//** Divider color for between dropdown items.
$dropdown-divider-bg:            #e5e5e5;

//** Dropdown link text color.
$dropdown-link-color:            $gray-dark;
//** Hover color for dropdown links.
$dropdown-link-hover-color:      darken($gray-dark, 5%);
//** Hover background for dropdown links.
$dropdown-link-hover-bg:         #f5f5f5;

//** Active dropdown menu item text color.
$dropdown-link-active-color:     $component-active-color;

//** Disabled dropdown menu item background color.
$dropdown-link-disabled-color:   $gray-light;

//** Text color for headers within dropdown menus.
$dropdown-header-color:          $gray-light;

//** Deprecated `$dropdown-caret-color` as of v3.1.0
$dropdown-caret-color:           #000;


//=== Inverted navbar
// Reset inverted navbar basics
$navbar-inverse-color:                      lighten($gray-light, 15%) !default;
$navbar-inverse-bg:                         #222 !default;
$navbar-inverse-border:                     darken($navbar-inverse-bg, 10%) !default;

// Inverted navbar links
$navbar-inverse-link-color:                 lighten($gray-light, 15%) !default;
$navbar-inverse-link-hover-color:           #fff !default;
$navbar-inverse-link-hover-bg:              transparent !default;
$navbar-inverse-link-active-color:          $navbar-inverse-link-hover-color !default;
$navbar-inverse-link-active-bg:             darken($navbar-inverse-bg, 10%) !default;
$navbar-inverse-link-disabled-color:        #444 !default;
$navbar-inverse-link-disabled-bg:           transparent !default;

// Inverted navbar brand label
$navbar-inverse-brand-color:                $navbar-inverse-link-color !default;
$navbar-inverse-brand-hover-color:          #fff !default;
$navbar-inverse-brand-hover-bg:             transparent !default;

// Inverted navbar toggle
$navbar-inverse-toggle-hover-bg:            #333 !default;
$navbar-inverse-toggle-icon-bar-bg:         #fff !default;
$navbar-inverse-toggle-border-color:        #333 !default;


//== Navs
//
//##

//=== Shared nav styles
$nav-link-padding:                          10px 15px !default;
$nav-link-hover-bg:                         $gray-lighter !default;

$nav-disabled-link-color:                   $gray-light !default;
$nav-disabled-link-hover-color:             $gray-light !default;

//== Tabs
$nav-tabs-border-color:                     #ddd !default;

$nav-tabs-link-hover-border-color:          $gray-lighter !default;

$nav-tabs-active-link-hover-bg:             $body-bg !default;
$nav-tabs-active-link-hover-color:          $gray !default;
$nav-tabs-active-link-hover-border-color:   #ddd !default;

$nav-tabs-justified-link-border-color:            #ddd !default;
$nav-tabs-justified-active-link-border-color:     $body-bg !default;

//== Navbar
//
//##

// Basics of a navbar
$navbar-height:                    60px;
$navbar-margin-bottom:             $line-height-computed;
$navbar-border-radius:             0;
$navbar-padding-horizontal:        calc(30px / 2);
$navbar-padding-vertical:          calc(($navbar-height - $line-height-computed) / 2);
$navbar-collapse-max-height:       340px;

$navbar-default-color:             white;
$navbar-default-bg:                $gray-lighter;
$navbar-default-border:            0;

// Navbar links
$navbar-default-link-color:                white;
$navbar-default-link-hover-color:          darken(white, 10%);
$navbar-default-link-hover-bg:             darken($brand-primary, 10%);
$navbar-default-link-active-color:         darken(white, 10%);
$navbar-default-link-active-bg:            darken($brand-primary, 10%);
$navbar-default-link-disabled-color:       lighten($brand-primary, 40%);
$navbar-default-link-disabled-bg:          transparent;

// Navbar brand label
$navbar-default-brand-color:               $navbar-default-link-color;
$navbar-default-brand-hover-color:         darken($navbar-default-brand-color, 10%);
$navbar-default-brand-hover-bg:            transparent;

// Navbar toggle
$navbar-default-toggle-hover-bg:           #ddd; // RAINCHECK
$navbar-default-toggle-icon-bar-bg:        #888; // RAINCHECK
$navbar-default-toggle-border-color:       transparent;

//== Thumbnails
//
//##

//** Padding around the thumbnail image
$thumbnail-padding:           4px;
//** Thumbnail background color
$thumbnail-bg:                $body-bg;
//** Thumbnail border color
$thumbnail-border:            #ddd;
//** Thumbnail border radius
$thumbnail-border-radius:     $border-radius-base;

//** Custom text color for thumbnail captions
$thumbnail-caption-color:     $text-color;
//** Padding around the thumbnail caption
$thumbnail-caption-padding:   9px;

//** Horizontal line color.
$hr-border:                   $gray-lighter;

//== Pills
$nav-pills-border-radius:                   $border-radius-base;
$nav-pills-active-link-hover-bg:            $brand-primary;
$nav-pills-active-link-hover-color:         $component-active-color;

//== Alerts
//
//## Define alert colors, border radius, and padding.

$alert-padding:               15px;
$alert-border-radius:         $border-radius-base;
$alert-link-font-weight:      bold;

$alert-success-bg:            $state-success-bg;
$alert-success-text:          $state-success-text;
$alert-success-border:        $state-success-border;

$alert-info-bg:               $state-info-bg;
$alert-info-text:             $state-info-text;
$alert-info-border:           $state-info-border;

$alert-warning-bg:            $state-warning-bg;
$alert-warning-text:          $state-warning-text;
$alert-warning-border:        $state-warning-border;

$alert-danger-bg:             $state-danger-bg;
$alert-danger-text:           $state-danger-text;
$alert-danger-border:         $state-danger-border;

//== Popovers
//
//##

//** Popover body background color
$popover-bg:                          #fff;
//** Popover maximum width
$popover-max-width:                   276px;
//** Popover border color
$popover-border-color:                rgba(0,0,0,.2);
//** Popover fallback border color
$popover-fallback-border-color:       #ccc;

//** Popover title background color
$popover-title-bg:                    darken($popover-bg, 3%);

//** Popover arrow width
$popover-arrow-width:                 10px;
//** Popover arrow color
$popover-arrow-color:                 #fff;

//** Popover outer arrow width
$popover-arrow-outer-width:           ($popover-arrow-width + 1);
//** Popover outer arrow color
$popover-arrow-outer-color:            lighten($popover-border-color, 5%);
//** Popover outer arrow fallback color
$popover-arrow-outer-fallback-color:  darken($popover-fallback-border-color, 20%);

//== Tables
//
//## Customizes the `.table` component with basic values, each used across all table variations.

//** Padding for `<th>`s and `<td>`s.
$table-cell-padding:            8px;
//** Padding for cells in `.table-condensed`.
$table-condensed-cell-padding:  5px;

//** Default background color used for all tables.
$table-bg:                      transparent;
//** Background color used for `.table-striped`.
$table-bg-accent:               #f9f9f9;
//** Background color used for `.table-hover`.
$table-bg-hover:                #f5f5f5;
$table-bg-active:               $table-bg-hover;

//** Border color for table and cell borders.
$table-border-color:            #ddd;

//== Grid system
//
//## Define your custom responsive grid.

//** Number of columns in the grid.
$grid-columns:              12 !default;
//** Padding between columns. Gets divided in half for the left and right.
$grid-gutter-width:         30px !default;
// Navbar collapse
//** Point at which the navbar becomes uncollapsed.
$grid-float-breakpoint:     $screen-sm-min !default;
//** Point at which the navbar begins collapsing.
$grid-float-breakpoint-max: ($grid-float-breakpoint - 1) !default;


//-- Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.
//
// Note: These variables are not generated into the Customizer.

$zindex-navbar:            1000 !default;
$zindex-dropdown:          1000 !default;
$zindex-popover:           1060 !default;
$zindex-tooltip:           1070 !default;
$zindex-navbar-fixed:      1030 !default;
$zindex-modal-background:  1040 !default;
$zindex-modal:             1050 !default;

//== Breadcrumbs
//
//##

$breadcrumb-padding-vertical:   8px !default;
$breadcrumb-padding-horizontal: 15px !default;
//** Breadcrumb background color
$breadcrumb-bg:                 #f5f5f5 !default;
//** Breadcrumb text color
$breadcrumb-color:              #ccc !default;
//** Text color of current page in the breadcrumb
$breadcrumb-active-color:       $gray-light !default;
//** Textual separator for between breadcrumb elements
$breadcrumb-separator:          "/" !default;

//== List group
//
//##

//** Background color on `.list-group-item`
$list-group-bg:                 #fff !default;
//** `.list-group-item` border color
$list-group-border:             #ddd !default;
//** List group border radius
$list-group-border-radius:      $border-radius-base !default;

//** Background color of single list items on hover
$list-group-hover-bg:           #f5f5f5 !default;
//** Text color of active list items
$list-group-active-color:       $component-active-color !default;

//** Text color of disabled list items
$list-group-disabled-color:      $gray-light !default;
//** Background color of disabled list items
$list-group-disabled-bg:         $gray-lighter !default;
//** Text color for content within disabled list items
$list-group-disabled-text-color: $list-group-disabled-color !default;

$list-group-link-color:         #555 !default;
$list-group-link-hover-color:   $list-group-link-color !default;
$list-group-link-heading-color: #333 !default;


//== Pagination
//
//##

$pagination-bg:                        #fff !default;
$pagination-border:                    #ddd !default;

$pagination-hover-bg:                  $gray-lighter !default;
$pagination-hover-border:              #ddd !default;

$pagination-active-color:              #fff !default;

$pagination-disabled-color:            $gray-light !default;
$pagination-disabled-bg:               #fff !default;
$pagination-disabled-border:           #ddd !default;

//== Modals
//
//##

//** Padding applied to the modal body
$modal-inner-padding:         15px !default;

//** Padding applied to the modal title
$modal-title-padding:         15px !default;
//** Modal title line-height
$modal-title-line-height:     $line-height-base !default;

//** Background color of modal content area
$modal-content-bg:                             #fff !default;
//** Modal content border color
$modal-content-border-color:                   rgba(0,0,0,.2) !default;
//** Modal content border color **for IE8**
$modal-content-fallback-border-color:          #999 !default;

//** Modal backdrop background color
$modal-backdrop-bg:           #000 !default;
//** Modal backdrop opacity
$modal-backdrop-opacity:      .5 !default;
//** Modal header border color
$modal-header-border-color:   #e5e5e5 !default;
//** Modal footer border color
$modal-footer-border-color:   $modal-header-border-color !default;

$modal-lg:                    900px !default;
$modal-md:                    600px !default;
$modal-sm:                    300px !default;



//== Type
//
//##

//** Horizontal offset for forms and lists.
$component-offset-horizontal: 180px !default;
//** Text muted color
$text-muted:                  $gray-light !default;
//** Abbreviations and acronyms border color
$abbr-border-color:           $gray-light !default;
//** Headings small color
$headings-small-color:        $gray-light !default;
//** Blockquote small color
$blockquote-small-color:      $gray-light !default;
//** Blockquote font size
$blockquote-font-size:        ($font-size-base * 1.25) !default;
//** Blockquote border color
$blockquote-border-color:     $gray-lighter !default;
//** Page header border color
$page-header-border-color:    $gray-lighter !default;
//** Width of horizontal description list titles
$dl-horizontal-offset:        $component-offset-horizontal !default;
//** Point at which .dl-horizontal becomes horizontal
$dl-horizontal-breakpoint:    $grid-float-breakpoint !default;
//** Horizontal line color.
$hr-border:                   $gray-lighter !default;

//== Badges
//
//##

$badge-color:                 #fff !default;
//** Linked badge text color on hover
$badge-link-hover-color:      #fff !default;
$badge-bg:                    $gray-light !default;

//** Badge background color in active nav link
$badge-active-bg:             #fff !default;

$badge-font-weight:           bold !default;
$badge-line-height:           1 !default;
$badge-border-radius:         10px !default;
