@font-face {
  font-family: 'videogular';
  src:  url('/assets/fonts/videogular2/videogular.eot?hj1wei');
  src:  url('/assets/fonts/videogular2/videogular.eot?hj1wei#iefix') format('embedded-opentype'),
  url('/assets/fonts/videogular2/videogular.ttf?hj1wei') format('truetype'),
  url('/assets/fonts/videogular2/videogular.woff?hj1wei') format('woff'),
  url('/assets/fonts/videogular2/videogular.svg?hj1wei#videogular') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="vg-icon-"], [class*=" vg-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'videogular' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  display: flex;
  align-items: center;
  font-size: 24px;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.vg-icon-closed_caption:before {
  content: "\e006";
}
.vg-icon-pause:before {
  content: "\e018";
}
.vg-icon-play_arrow:before {
  content: "\e01b";
}
.vg-icon-repeat:before {
  content: "\e023";
}
.vg-icon-replay:before {
  content: "\e025";
}
.vg-icon-skip_next:before {
  content: "\e027";
}
.vg-icon-skip_previous:before {
  content: "\e028";
}
.vg-icon-stop:before {
  content: "\e02a";
}
.vg-icon-volume_down:before {
  content: "\e030";
}
.vg-icon-volume_mute:before {
  content: "\e031";
}
.vg-icon-volume_off:before {
  content: "\e032";
}
.vg-icon-volume_up:before {
  content: "\e033";
}
.vg-icon-hd:before {
  content: "\e035";
}
.vg-icon-forward_10:before {
  content: "\e038";
}
.vg-icon-forward_30:before {
  content: "\e039";
}
.vg-icon-replay_10:before {
  content: "\e03b";
}
.vg-icon-replay_30:before {
  content: "\e03c";
}
.vg-icon-fullscreen:before {
  content: "\e20c";
}
.vg-icon-fullscreen_exit:before {
  content: "\e20d";
}
