//
// Tables
// --------------------------------------------------

// Zebra-striping
//
// Default zebra-stripe styles (alternating gray and transparent backgrounds)

.table-striped {
	> tbody > tr:nth-of-type(odd) {
		> td,
		> th {
			background-color: $table-bg-accent;
		}
	}
}


// Hover effect
//
// Placed here since it has to come after the potential zebra striping

.table-hover {
	> tbody > tr:hover {
		> td,
		> th {
			background-color: $table-bg-hover;
		}
	}
}

// Responsive tables
//
// Wrap your tables in `.table-responsive` and we'll make them mobile friendly
// by enabling horizontal scrolling. Only applies <768px. Everything above that
// will display normally.

.table-responsive {
	@media screen and (max-width: $screen-xs-max) {
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
	}
}
